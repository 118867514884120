import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Text from '../common/Text';
import Image from '../common/Image';
import appStyles from '../../../../config/appStyles.js';
const useStyles = makeStyles((theme) => appStyles.notification(theme));

const Notification = ({ notification }) => {
  const classes = useStyles();
  const { t } = useTranslation(['components']);

  return (
    <Box className={classes.notification}>
      <Box className={classes.notificationIconWrapper}>
        <Image className={classes.notificationIcon} name="alert" />
      </Box>
      <Text className={classes.notificationText}>
        {t(`components:Package.notifications.${notification?.name}`)}
      </Text>
    </Box>
  );
};

export default Notification;
